<template>
  <div class="d-flex gap-1">
    <v-select
      label="Status do Fornecedor"
      v-model="vendor_status"
      :items="vendor_status_table"
      @input="setVendorStatus"
      item-text="text"
      item-value="id"
      color="primary"
      rounded
      solo
      dense
      light
      hide-details
    >
      <template v-slot:item="{ item }">
        <v-list-item-avatar size="20">
          <v-icon
            small
            :color="item.color"
            >{{ item.icon }}</v-icon
          >
        </v-list-item-avatar>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </template>
    </v-select>
    <v-autocomplete
      v-model="ncm"
      @focus.once="getFactors"
      @input="setFactor"
      :items="factors"
      label="Fator"
      item-value="id"
      item-text="cost"
      rounded
      solo
      dense
      light
      hide-details
    >
      <template v-slot:no-data>
        <div
          class="px-4"
          v-if="isLoadingFactor"
        >
          <v-progress-linear
            color="primary"
            indeterminate
          />
        </div>
        <div
          v-else
          class="px-4 caption"
        >
          Nenhum fator encontrado
        </div>
      </template>
      <template v-slot:item="{ item }">
        <v-list>
          <v-list-item>{{ item.cost }}</v-list-item>
        </v-list>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="ncm"
      @focus.once="getPrints"
      @input="setPrint"
      :items="prints"
      label="Gravação"
      item-value="id"
      item-text="name"
      rounded
      solo
      dense
      light
      hide-details
    >
      <template v-slot:no-data>
        <div
          class="px-4"
          v-if="isLoadingPrint"
        >
          <v-progress-linear
            color="primary"
            indeterminate
          />
        </div>
        <div
          v-else
          class="px-4 caption"
        >
          Nenhuma gravação encontrada
        </div>
      </template>
      <template v-slot:item="{ item }">
        <v-list>
          <v-list-item>{{ item.name }}</v-list-item>
        </v-list>
      </template>
    </v-autocomplete>

    <v-autocomplete
      v-model="ncm"
      @focus.once="getNcms"
      @input="setNcm"
      :items="ncms"
      label="NCM"
      item-value="id"
      item-text="title"
      rounded
      solo
      dense
      light
      hide-details
    >
      <template v-slot:no-data>
        <div
          class="px-4"
          v-if="isLoadingNcm"
        >
          <v-progress-linear
            color="primary"
            indeterminate
          />
        </div>
        <div
          v-else
          class="px-4 caption"
        >
          Nenhum NCM encontrado
        </div>
      </template>
      <template v-slot:item="{ item }">
        <v-list>
          <v-list-item>{{ item.title }}</v-list-item>
        </v-list>
      </template>
    </v-autocomplete>

    <v-select
      label="Status"
      @input="updateMultipleStatus"
      :items="[
        { title: 'Ativo', value: true },
        { title: 'Inativo', value: false },
      ]"
      item-value="value"
      item-text="title"
      solo
      rounded
      dense
      hide-details
      light
    />

    <v-btn
      class="white error--text"
      rounded
      @click="deleteMultiple"
    >
      Excluir
      <v-icon right>fas fa-trash</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
  },
  data: () => ({
    statusItems: [
      { text: 'Ativar', value: true },
      { text: 'Desativar', value: false },
    ],
    vendor_status_table: [
      { id: 1, title: 'Ok', icon: 'fas fa-check', color: 'success' },
      { id: 2, title: 'Consultar', icon: 'fas fa-search', color: 'blue' },
      { id: 3, title: 'Sem estoque', icon: 'fas fa-box-open', color: 'error' },
      {
        id: 4,
        title: 'Fora de linha',
        icon: 'fas fa-times-circle',
        color: 'grey',
      },
    ],
    categoriesItems: [],
    vendor_status: null,
    ncms: [],
    prints: [],
    factors: [],
    ncm: null,
    isLoadingNcm: true,
    isLoadingPrint: true,
    isLoadingFactor: true,
  }),
  computed: {
    selectedIds() {
      let ids = []

      this.selectedItems.map((item) => {
        ids.push(item.id)
      })

      return ids
    },
    selectedViewVendors() {
      let ids = []

      this.selectedItems.map((item) => {
        ids.push(item.vendors.view_id)
      })

      return ids
    },
  },
  methods: {
    updateMultipleStatus(value) {
      let selectedIds = this.selectedItems.map((item) => {
        return item.id
      })
      this.$api
        .post(`products/status/mass`, {
          selectedIds: selectedIds,
          value: value,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Status alterado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
              this.$emit('modalClicked')
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar alterar status',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    deleteMultiple() {
      let selectedIds = this.selectedItems.map((item) => {
        return item.id
      })

      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo deletar ${this.selectedItems.length} Produtos?`,
        confirm: () => {
          this.$api
            .post(`products/massdelete`, selectedIds)
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Produtos excluídos com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  this.$emit('modalClicked')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir os Produtos selecionados',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    getNcms() {
      this.$api
        .get('ncms')
        .then((res) => {
          this.ncms = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingNcm = false
        })
    },
    getPrints() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.prints = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingPrint = false
        })
    },
    getFactors() {
      this.$api
        .get('product_factors')
        .then((res) => {
          this.factors = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingFactor = false
        })
    },
    setNcm(e) {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo alterar o NCM de ${this.selectedItems.length} Produtos?`,
        confirm: () => {
          this.$api
            .patch(`products/update-mass-ncm`, {
              value: e,
              items: this.selectedIds,
            })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: "NCM's alterados com sucesso",
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  this.$emit('modalClicked')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar alterar o NCM dos produtos selecionados',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    setFactor(e) {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo alterar o Fator de ${this.selectedItems.length} Produtos?`,
        confirm: () => {
          this.$api
            .patch(`products/update-mass-factor`, {
              value: e,
              items: this.selectedIds,
            })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Fatores alterados com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  this.$emit('modalClicked')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar alterar o Fator dos produtos selecionados',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    setVendorStatus(e) {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo alterar o status do fornecedor de ${this.selectedItems.length} Produtos?`,
        confirm: () => {
          this.$api
            .patch(`products/update-mass-vendor-status`, {
              value: e,
              items: this.selectedViewVendors,
            })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Status alterados com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  this.$emit('modalClicked')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar alterar o status do fornecedor dos produtos selecionados',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    setPrint(e) {
      this.$store.commit('setModal', {
        show: true,
        component: 'radio',
        text: 'Atenção!',
        confirm: (action) => {
          if (action === 'add') {
            this.$api
              .patch(`products/update-mass-print`, {
                value: e,
                items: this.selectedIds,
              })
              .then(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'success',
                  text: 'Gravação adicionada com sucesso',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                    this.$emit('modalClicked')
                  },
                })
              })
              .catch(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'error',
                  text: 'Ops! Falha ao tentar adicionar esta Gravação aos produtos selecionados',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                  },
                })
              })
          } else {
            this.$api
              .patch(`products/remove-mass-print`, {
                value: e,
                items: this.selectedIds,
              })
              .then(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'success',
                  text: 'Gravação removida com sucesso',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                    this.$emit('modalClicked')
                  },
                })
              })
              .catch(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'error',
                  text: 'Ops! Falha ao tentar remover esta Gravação aos produtos selecionados',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                  },
                })
              })
          }
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>

<style>
.gap-1 {
  column-gap: 10px;
}
</style>
